@import '../../styles/variables.scss';

.app-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  margin: 1rem;
  border-radius: $radius;

  background-color: $grey-darker;
  color: $white;

  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;

  animation-duration: 1.64s;
  max-width: 50%;

  z-index: 1;
}

@include touch {
  .app-notification {
    border-radius: 0;
    margin: 0;
    max-width: 100%;
  }
}

@include mobile {
  .app-notification {
    width: 100%;
    max-width: 100%;
  }
}
